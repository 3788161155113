import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PageLayout from 'examples/LayoutContainers/PageLayout';
// import {
//     randomCreatedDate,
//     randomTraderName,
//     randomUpdatedDate,
// } from '@mui/x-data-grid-generator';

export default function BasicEditingGrid() {
    return (
        <PageLayout>

            <div style={{ height: 300, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                />
            </div>
        </PageLayout>
    );
}

const columns = [
    { field: 'name', headerName: 'Name', width: 180, editable: true },
    {
        field: 'age',
        headerName: 'Age',
        type: 'number',
        editable: true,
        align: 'left',
        headerAlign: 'left',
    },
];

const rows = [
    {
        id: 1,
        name: "jhon deo",
        age: 25,
    },
    {
        id: 2,
        name: "jhon deo",
        age: 25,
    },
];