import PageLayout from "examples/LayoutContainers/PageLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Grid } from "@mui/material";
import Loader from "examples/Loader";
import MUIDataTable from "mui-datatables";

import { useEffect, useState } from "react";
import { useAgencyInfo } from "context/agency";
import { useAppServices } from "hook/services";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import CreateModal from "./components/Modals/Create"
import env from "config";
function Calender() {
  const navigate = useNavigate();
  // const [agency] = useAgencyInfo();
  const { company_id, app_id } = useParams()
  const [agency, setAgency] = useState({})
  const Service = useAppServices();
  const [Conjo, setConjo] = useState([]);
  const [data, setdata] = useState([]);
  const [dateFilterChecked, setDateFilterChecked] = useState(true);
  const [processing, setProcessing] = useState(true);
  const [refresh, setrefresh] = useState(true);
  const [copy, setcopy] = useState(false);

  const handlecopy = () => {
    setcopy(true);
    setTimeout(() => {
      setcopy(false);
    }, 2000);
  };
  const copyToClipboard = (url) => {
    const text = url

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    // Append the textarea to the body
    document.body.appendChild(textarea);

    // Select the text inside the textarea
    textarea.select();

    // Execute the copy command
    document.execCommand('copy');
    setcopy(true)
    // Remove the textarea from the DOM
    document.body.removeChild(textarea);

    setTimeout(() => {
      setcopy(false)
    }, 2000);
  };
  const columns = [
    {
      name: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "Location",
      options: {
        filter: false,
      },
    },
    {
      name: "Created At",
      options: {
        filter: false,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    selectableRows: "none",
    filterType: "multiselect",
    responsive: "standard",
  };
  const getCalendars = async () => {
    const { response } = await Service.calendars.filter({
      query: `company_id=${company_id}&app_id=${app_id}`,
    });
    console.log(response, "getCalendars");
    if (response) {
      const temp_loc = [];
      var tabel_data = [];
      response.data.forEach((element) => {
        const temp = [
          element.name,
          element.location.label,
          element.createdAt,
          <>
            {/* <CopyToClipboard
              text={`http://localhost:3001/calender/${element._id}/${agency._id}`}
              onCopy={handlecopy}
              ml={2}
            > */}
            <MDButton variant="contained" color="info" size="small" ml={2} onClick={() => copyToClipboard(`https://calendarmaps.levelupmarketplace.io/calender/${app_id}/${element._id}/${response.agency._id}`)}>
              {copy ? "Copied" : "Copy"}
            </MDButton>
            {/* </CopyToClipboard> */}
          </>,
        ];
        tabel_data.push(temp);
      });
      setConjo(tabel_data);
      setAgency(response.agency)
      for (let index = 0; index < response.locations.length; index++) {
        const element = response.locations[index];
        temp_loc.push({
          label: element.name,
          value: element.id,
        });
      }
      setdata(temp_loc);
      setProcessing(false);
    } else {
      setProcessing(false);
    }
  };
  // "TtzMWtV2AxjW6LLcxafM"
  const onLoad = () => {
    getCalendars();
  };
  useEffect(async () => {
    onLoad();
  }, []);
  const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.CALENDER_MAP_ASSOCIATE}&client_id=${env.GHL.CALENDER_MAP_CLIENT_ID}&scope=${env.GHL.CALENDER_MAP_SCOPE}&state=${agency?._id}`
  const hanldeConnect = () => {
    var windowFeatures = 'width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes'

    // Open the URL in a new window with the specified features
    window.open(auth_url, '_blank', windowFeatures)
  }
  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <PageLayout>
          <>
            {/* <DashboardNavbar
              type="calender_page"
              handleChange={onLoad}
              data={data}
            /> */}
            <Grid container>
              {
                agency?.agency_ghl ?
                  <Grid item xs={12} mb={3} display={"flex"} justifyContent={"flex-end"}>
                    <CreateModal data={data} handleChange={onLoad} />
                  </Grid>
                  :
                  <Grid item xs={12} mb={3} display={"flex"} justifyContent={"flex-end"}>
                    <MDButton
                      variant="contained"
                      color="info"
                      onClick={hanldeConnect}
                    >
                      Connect App
                    </MDButton>
                  </Grid>
              }
              <Grid item xs={12}>
                <MUIDataTable
                  title={"Calendar"}
                  data={Conjo}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          </>
        </PageLayout>
      )}
    </>
  );
}

export default Calender;
