import { Grid } from "@mui/material";
import CreateModal from "../Modals/Create";
import MUIDataTable from 'mui-datatables'

export default function AppsComponent({ Conjo, onLoad, columns, options }) {
    return (
        <>
            <Grid container>
                <Grid item xs={12} mb={3} display={"flex"} justifyContent={"flex-end"}>
                    <CreateModal handleChange={onLoad} />
                </Grid>

                <Grid item xs={12}>
                    <MUIDataTable title={'Apps'} data={Conjo} columns={columns} options={options} />
                </Grid>
            </Grid>
        </>
    )
}