/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router components
import { Routes, Route, Navigate, useParams } from 'react-router-dom'

// Material Dashboard 2 React routes
import routes from './routes'
import { useEffect, useState } from 'react';
import { useAppServices } from 'hook/services';
import ssoHandler from '../ssoHandler';
import Loader from 'examples/Loader';
import Sidenav from 'examples/Sidenav';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

export default function Integration() {
  const { app_id } = useParams()
  const { SSO, checkSSO } = ssoHandler();
  const [ssodata, setssodata] = useState({})
  const [loader, setloader] = useState(true)
  const AppService = useAppServices()
  const AppsData = async () => {
    const { response } = await AppService.apps.get({
      query: ``,
    });
    console.log(response, 'AppsData');
    if (response) {
      var active_apps = response.data.filter(function (item) {
        return item.status.value == "active";
      });
      var newArray = response.data.filter(function (item) {
        return item.app_id == app_id;
      });
      console.log(newArray, 'newArraynewArray');
      // checkSSO({});
      if (newArray[0]) {
        checkSSO(newArray[0]);
      }
    }
  };
  // useEffect(() => {
  //   AppsData();
  // }, []);
  // useEffect(() => {
  //   if (SSO != '' && SSO != undefined) {
  //     var data = JSON.parse(SSO);
  //     setssodata(data)
  //     setloader(false);
  //   }
  //   console.log(SSO, 'SSOSSO');
  // }, [SSO]);
  const getRoutes = (allRoutes) => allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse)
    }
    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />
    }
    return null
  })


  return (
    // loader ?
    //   <Loader />
    //   :
    <>
      <DashboardLayout>
        <Sidenav brandName="Dashboard" routes={routes} />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/auth" />} />
        </Routes>
      </DashboardLayout>
    </>
  )
}
