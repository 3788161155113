import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Multiselect from "./components/Multiselect";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import { useParams } from "react-router-dom";
const ServeyModal = (props) => {
  const Service = useAppServices();
  const [agency] = useAgencyInfo();
  const { company_id, app_id } = useParams()
  const [openAddProduct, setOpenAddProduct] = useState(false);
  useEffect(async () => { }, []);
  function AddProduct({ open, onClose }) {
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setProcessing(true);
      const payload = {
        name: e.target.name.value,
        location: JSON.parse(e.target.location.value),
        company_id: company_id,
        app_id: app_id,
      };
      const { response } = await Service.servey.create({ payload });
      if (response) {
        setOpenAddProduct(false);
        setProcessing(false);
        props.handleChange();
      } else {
        setProcessing(false);
      }
    };
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Servey
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <Grid item md={12}>
              <MDBox mb={1}>
                <MDTypography variant="button" display="block" gutterBottom>
                  Name
                </MDTypography>
                <MDInput
                  defaultValue=""
                  id="bootstrap-input"
                  fullWidth
                  placeholder="name"
                  name="name"
                />
              </MDBox>
            </Grid>
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                <span sx={{ display: "none" }}>Select Location</span>
              </MDTypography>
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                <Multiselect data={props.data} name="location" />
              </MDBox>
            </Grid>

            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={processing}
                loading={processing}
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        sx={{ marginLeft: 3 }}
        variant="outlined"
        color="info"
        startIcon={<AddBoxIcon />}
        onClick={() => setOpenAddProduct(true)}
      >
        Survey
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default ServeyModal;
