import MDButton from "components/MDButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import React, { useEffect, useState } from "react";
import MDModal from "components/MDModal";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Multiselect from "./components/Multiselect";
import env from "config";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useAppServices } from "hook/services";
import { useAgencyInfo } from "context/agency";
import { useParams } from "react-router-dom";
const CalenderModal = (props) => {
  const Service = useAppServices();
  const { company_id, app_id } = useParams()
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const BASE_URL = `${env.API_URL}/v1`;

  useEffect(async () => { }, []);
  function AddProduct({ open, onClose }) {
    const initState = {
      name: "",
    };
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setProcessing(true);
      const payload = {
        name: e.target.name.value,
        location: JSON.parse(e.target.location.value),
        calender: JSON.parse(e.target.calender.value),
        company_id: company_id,
        app_id: app_id,
      };
      const { response } = await Service.calendars.create({ payload });
      if (response) {
        props.handleChange(true);
        setOpenAddProduct(false);
        setProcessing(false);
      } else {
        setProcessing(false);
      }
    };
    const ModalWrappar = () => {
      const [Calender_data, setCalender_data] = useState([]);
      const handleCalenders = async (location_id) => {
        var loc_id = JSON.parse(location_id);
        // alert(loc_id.value);
        // return 0;
        const { response } = await Service.calendars.GetCalendars({
          query: `locationId=${loc_id.value}&company_id=${company_id}&app_id=${app_id}`,
        });
        var temp_calenders = [];
        for (let index = 0; index < response.data.length; index++) {
          const element = response.data[index];

          temp_calenders.push({
            label: element.name,
            value: element.id,
          });
        }
        setCalender_data(temp_calenders);

        console.log(temp_calenders, "calendarscalendars");
      };
      return (
        <>
          <Grid item md={12}>
            <MDBox mb={1}>
              <MDTypography variant="button" display="block" gutterBottom>
                Name
              </MDTypography>
              <BootstrapInput
                defaultValue=""
                id="bootstrap-input"
                fullWidth
                placeholder="name"
                name="name"
              />
            </MDBox>
          </Grid>
          <Grid item md={12}>
            <MDTypography mb={1} variant="button" display="block" gutterBottom>
              <span sx={{ display: "none" }}>Select Location</span>
            </MDTypography>
            <MDBox mb={1} sx={{ fontSize: "14px" }}>
              <Multiselect
                data={props.data}
                name="location"
                handleCalenders={handleCalenders}
              />
            </MDBox>
          </Grid>
          {Calender_data.length != 0 ? (
            <Grid item md={12}>
              <MDTypography
                mb={1}
                variant="button"
                display="block"
                gutterBottom
              >
                <span sx={{ display: "none" }}>Select Calender</span>
              </MDTypography>
              <MDBox mb={1} sx={{ fontSize: "14px" }}>
                <Multiselect
                  isMulti={true}
                  data={Calender_data}
                  name="calender"
                  handleCalenders={() => { }}
                />
              </MDBox>
            </Grid>
          ) : (
            <></>
          )}
        </>
      );
    };
    return (
      <MDModal open={open} onClose={onClose}>
        <MDBox>
          <MDTypography component="h1" mb={3} variant="h5">
            Calender
          </MDTypography>

          <MDBox
            component="form"
            onSubmit={handleSubmit}
            role="form"
            sx={{ width: 335 }}
          >
            <ModalWrappar />
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={processing}
                loading={processing}
                sx={{ mt: 4, mb: 1 }}
                fullWidth
              >
                Add
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDModal>
    );
  }
  const closeAddProduct = (subscription) => {
    // if (subscription?._id)
    setOpenAddProduct(false);
  };
  return (
    <>
      <MDButton
        sx={{ marginLeft: 3 }}
        variant="outlined"
        color="info"
        startIcon={<AddBoxIcon />}
        onClick={() => setOpenAddProduct(true)}
      >
        Calender
      </MDButton>
      <AddProduct open={openAddProduct} onClose={closeAddProduct} />
    </>
  );
};

export default CalenderModal;
