import { Card, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Loader from "examples/Loader";
import { useAppServices } from "hook/services";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
const MyApps = () => {
    const AppService = useAppServices();
    const { app_id, location_id } = useParams();
    const { search } = useLocation()
    const type = new URLSearchParams(search).get('type')
    const [app, setApp] = useState({});
    const [credentials, setCredentials] = useState({});
    const [loader, setloader] = useState(true);
    const iframeStyles = {
        width: "100%",
        height: "87vh",
        border: "none",
    };
    const getApp = async () => {
        var query = `app_id=${app_id}`
        if (type == "free") {
            var query = `free_app.app_id=${app_id}`
        }
        const { response } = await AppService.apps.filter({
            query: query,
        });
        if (response) {
            setApp(response.data);
            setloader(false);
        } else {
            setloader(false);
        }
    };
    const getCreds = async () => {
        const { response } = await AppService.app_token.get_credentials({
            query: `type=${type}&location_id=${location_id}&app_id=${app_id}`,
        })
        console.log(response, 'getCredsgetCreds')
        if (response) {
            setCredentials(response?.data?.credentials)
            setloader(false)
        } else {
            setloader(false)
        }
    }
    const onLoad = async () => {
        getCreds()
        getApp();
    };
    useEffect(() => {
        onLoad();
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const formValues = {};
        for (let [name, value] of formData.entries()) {
            formValues[name] = value;
        }
        const payload = {
            app_id: app_id,
            location_id: location_id,
            type: type,
            credentials: formValues,
        };
        console.log(payload, "payloadpayload");
        const { response } = await AppService.app_token.save_credentials({
            payload: payload,
            toaster: true
        });
    };
    const handleConnect = () => {
        var windowFeatures =
            "width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes";

        // Open the URL in a new window with the specified features
        window.open(app?.url, "_blank", windowFeatures);
    };
    return (
        <>
            <div>
                {loader ? (
                    <Loader />
                ) : (
                    <>
                        {app?.free_app?.integrationType?.value == "form" ? (
                            <>

                                <div style={{
                                    minHeight: "95vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}>
                                    <Card
                                        style={{
                                            width: "500px",
                                            padding: '2rem',
                                            borderRadius: '29px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.9)',

                                        }}
                                    >
                                        <MDBox component="form"
                                            onSubmit={handleSubmit}
                                            role="form">
                                            <MDTypography textAlign="center" mb={3}>
                                                <h5>{app.name} Configuration</h5>
                                            </MDTypography>

                                            {app?.configration?.fields?.map((field, index) => (
                                                <MDBox
                                                    mb={2}
                                                    key={index}
                                                >
                                                    <MDTypography sx={{ textTransform: "capitalize", fontSize: "16px" }} >{field.label}</MDTypography>
                                                    <MDTypography ml={0.5} sx={{ fontSize: "13px" }}>{field.helpingText}</MDTypography>
                                                    <TextField
                                                        defaultValue={credentials?.[field.name]}
                                                        sx={{ mt: '10px' }}
                                                        label={field.label}
                                                        variant="outlined"
                                                        fullWidth type="text" name={field.name} />
                                                </MDBox>
                                            ))}
                                            <MDBox mt={6}>
                                                <MDButton variant="gradient"
                                                    fullWidth
                                                    color="info"
                                                    type="submit">
                                                    Submit
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </Card>
                                    {/* <div
                                        style={{
                                            marginLeft: "470px",
                                            padding: "5rem",
                                            width: "100%",
                                            zIndex: "-1",
                                            marginTop: "2rem"

                                        }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: app?.content }} />
                                    </div> */}
                                </div>
                            </>
                        ) :
                            <></>
                            //  app?.integrationType?.value == "auth" ? (
                            //     <div
                            //         style={{
                            //             minHeight: "95vh",
                            //             width: "100%",
                            //             display: "flex",
                            //             justifyContent: "center",
                            //             alignItems: "center",
                            //             flexDirection: "column",
                            //         }}
                            //     >
                            //         <div
                            //             className="shadow"
                            //             style={{ width: "400px", padding: "1rem" }}
                            //         >
                            //             <h5 className="text-center w-100 mb-5 mt-3">
                            //                 {app.name} Integration
                            //             </h5>
                            //             <MDButton
                            //                 className="w-100 mt-5"
                            //                 variant="primary"
                            //                 type="button"
                            //                 onClick={handleConnect}
                            //             >
                            //                 Connect
                            //             </MDButton>
                            //         </div>
                            //         <div
                            //             style={{
                            //                 position: "absolute",
                            //                 bottom: "1rem",
                            //                 left: "2rem",
                            //                 right: "2rem",
                            //             }}
                            //             dangerouslySetInnerHTML={{ __html: app?.content }}
                            //         />
                            //     </div>
                            // ) : (
                            //     <div className="mt-5 pt-2">
                            //         <iframe src={app?.url} style={iframeStyles}></iframe>
                            //     </div>
                            // )
                        }
                    </>
                )}
            </div>
        </>
    );
};

export default MyApps;
