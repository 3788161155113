import {
  Avatar,
  Checkbox,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useAgencyInfo } from "context/agency";
import Loader from "examples/Loader";
import { useAppServices } from "hook/services";
import { useParams } from "react-router-dom";
import "./components/style.css";
import Maps from "./components/maps";
import { useEffect, useState } from "react";
const Default = () => {
  const Service = useAppServices();
  const params = useParams();
  const [agency] = useAgencyInfo();
  const [loader, setloader] = useState(true);
  const [userList, setuserList] = useState([]);
  const [eventList, seteventList] = useState([]);
  const [CalendarList, setCalendarList] = useState([]);
  const [all_data, setall_data] = useState([]);
  const [CalenderIndex, setCalenderIndex] = useState(0);
  const [locations, setLocations] = useState([]);
  const [defaultProps, setdefaultProps] = useState({
    center: {
      lat: 31.4504, // Default Latitude
      lng: 73.135, // Default Longitude
    },
    zoom: 16,
  });
  const [coordinates, setCoordinates] = useState([]);
  // const locations = [
  //   { lat: 40.7128, lng: -74.006, title: "New York City" },
  //   { lat: 41.0522, lng: -75.006, title: "Los Angeles" },
  //   { lat: 41.8781, lng: -87.6298, title: "Chicago" },
  //   // Add more locations as needed
  // ];
  const geocodeAddresses = async (location_data) => {
    console.log(location_data, "location_data");
    const updatedCoordinates = await Promise.all(
      location_data.map(async (location) => {
        const API_KEY = "AIzaSyDvZeBveYG3wSJr1bmdE5kJ-0C92WG2GC4";
        const API_URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          location.address
        )}&key=${API_KEY}`;

        try {
          const response = await axios.get(API_URL);
          const { results } = response.data;
          console.log(results, "results");
          if (results.length > 0) {
            const { lat, lng } = results[0].geometry.location;
            return { name: location.address, position: { lat, lng } };
          } else {
            return null;
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          return null;
        }
      })
    );
    console.log(updatedCoordinates, "updatedCoordinates");
    const coordinate_data = updatedCoordinates.filter(
      (coord) => coord !== null
    );
    console.log(coordinate_data, "coordinate_data");
    const defaultProps_data = {
      center: {
        lat: coordinate_data[0]?.lat, // Default Latitude
        lng: coordinate_data[0]?.lng, // Default Longitude
      },
      zoom: 16,
    };
    setdefaultProps(defaultProps_data);
    setCoordinates(coordinate_data);
    console.log(defaultProps, "defaultProps");
  };

  const GetCalendarsData = async () => {
    const { response } = await Service.calendars.GetCalendarsData({
      query: `_id=${params.id}&agency_id=${params.agency_id}`,
    });
    console.log(response, "GetCalendarsData");
    if (response) {
      const temp_users = [];
      // const temp_events = [];
      const tempEventsData = [];
      const temp_calenders = [];
      for (
        let index = 0;
        index < response.AllUsers?.data?.users.length;
        index++
      ) {
        const element = response.AllUsers?.data?.users[index];
        temp_users.push({
          name: element.name,
          id: element.id,
        });
      }
      for (let index = 0; index < response.data.length; index++) {
        const element_c = response.data[index];
        temp_calenders.push(element_c.calendar);
        const temp_events = [];
        const temp_locations = [];

        for (let index = 0; index < element_c.data.length; index++) {
          const element_e = element_c.data[index];
          temp_locations.push({
            title: element_e.contact_data?.city,
            contact_id: element_e.contact_data?.id,
            address: `${element_e.contact_data?.city} ${element_e.contact_data?.address1}`,
          });
          temp_events.push({
            contact_name: element_e.contact_data?.firstName,
            country: element_e.contact_data?.country,
            contact_id: element_e.contact_data?.id,
            event_name: element_e.event?.title,
            event_startTime: element_e.event?.startTime,
            event_endTime: element_e.event?.endTime,
            user_name: element_e.user?.name,
            status: element_e.event?.appointmentStatus,
            user_id: element_e.user?.id,
          });
        }
        tempEventsData.push({
          calendar: element_c.calendar,
          event: temp_events,
          locations: temp_locations,
        });
      }
      console.log(temp_users, "temp_users");
      console.log(tempEventsData, "tempEventsData");
      setCalendarList(temp_calenders);
      setuserList(temp_users);
      seteventList(tempEventsData[0]?.event || []);
      setLocations(tempEventsData[0]?.locations || []);
      geocodeAddresses(tempEventsData[0]?.locations || []);
      setall_data(tempEventsData);
      // geocodeAddresses(tempEventsData[0]?.locations || []);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  const filterEvents = (data) => {
    // CalenderIndex
    // console.log(data, "data");
    // console.log(all_data, "all_data");
    if (all_data[CalenderIndex]?.event?.length) {
      var newArray = all_data[CalenderIndex]?.event?.filter(function (item) {
        console.log(item, "item");
        const formattedDate = item.event_startTime.toString().split("T")[0];
        console.log(formattedDate, "formattedDate");
        return formattedDate == data;
      });
      seteventList(newArray);
      var filterlocations = all_data[CalenderIndex]?.locations?.filter(
        (location) =>
          newArray.some((event) => event.contact_id === location.contact_id)
      );
      geocodeAddresses(filterlocations || []);
      console.log(newArray, "newArray");
    }
  };
  const onLoad = () => {
    // getMaps();

    GetCalendarsData();
  };
  const handleIndex = (index) => {
    console.log(index);
    setCalenderIndex(index);
    seteventList(all_data[index]?.event || []);
    console.log(all_data[index]?.event, "all_data[index]?.event");
    geocodeAddresses(all_data[index]?.locations || []);
  };
  const handleUsers = (checked, id) => {
    const users_data = all_data[CalenderIndex]?.event.filter(function (item) {
      return item.user_id == id;
    });
    if (users_data.length) {
      if (!checked) {
        var newArray = all_data[CalenderIndex]?.event.filter(function (item) {
          return item.user_id != id;
        });
      } else {
        var newArray = all_data[CalenderIndex]?.event.filter(function (item) {
          return item.user_id == id;
        });
      }
      seteventList(newArray);
    }
  };

  useEffect(async () => {
    onLoad();
  }, []);
  // useEffect(async () => {
  //   geocodeAddresses();
  // }, [locations]);
  // const userList = [
  //   { id: 1, name: "Alice" },
  //   { id: 2, name: "Bob" },
  //   { id: 3, name: "Charlie" },
  //   // Add more users here
  // ];
  const handleReset = () => {
    geocodeAddresses(all_data[CalenderIndex]?.locations || []);
    seteventList(all_data[CalenderIndex]?.event || []);
  };
  const getRandomColor = () => {
    const colors = [
      "#f44336",
      "#e91e63",
      "#9c27b0",
      "#673ab7",
      "#3f51b5",
      "#2196f3",
      "#03a9f4",
      "#00bcd4",
      "#009688",
      "#4caf50",
      "#8bc34a",
      "#cddc39",
      "#ffeb3b",
      "#ffc107",
      "#ff9800",
      "#ff5722",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const Marker = ({ text }) => (
    <div
      style={{
        color: "white",
        background: "blue",
        padding: "5px 10px",
        borderRadius: "50%",
      }}
    >
      {text}
    </div>
  );
  const formatdate = (rawDate) => {
    const date = new Date(rawDate);

    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate
  }
  return (
    <MDBox px={4} py={2}>
      {loader ? (
        <Loader />
      ) : (
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <MDTypography mr={1} variant="h5" display="block" gutterBottom>
                {/* <b> Appointments</b> */}
                Appointments
              </MDTypography>
              <input
                type="date"
                onChange={(e) => filterEvents(e.target.value)}
                className="form-control"
                style={{ width: "15rem" }}
                id=""
              />
              <select
                onChange={(e) => handleIndex(e.target.value)}
                defaultValue={CalendarList[0]?.value}
                style={{ marginLeft: "5px", width: "15rem" }}
                className="form-control"
                placeholder="Select Calender"
              >
                {CalendarList.map((calendar, index) => (
                  <option value={index}>{calendar.label}</option>
                ))}
              </select>
              <MDButton
                sx={{ marginLeft: "5px" }}
                variant="contained"
                color="info"
                size="small"
                onClick={handleReset}
              >
                Reset
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={2}>
            <MDTypography mr={1} variant="h6" display="block" gutterBottom>
              {/* <b> Appointments</b> */}
              Assigned Users
            </MDTypography>
            <List component="nav">
              {userList?.map((user, index) => (
                <div key={user.id}>
                  <ListItem button>
                    <Checkbox
                      onClick={(e) => handleUsers(e.target.checked, user.id)}
                      edge="start"
                      defaultChecked
                      tabIndex={-1}
                      disableRipple
                    />

                    <ListItemText primary={user.name} />
                    <Avatar
                      style={{
                        width: 24,
                        height: 24,
                        backgroundColor: getRandomColor(),
                        marginLeft: "auto",
                        marginRight: "15px",
                      }}
                    />
                  </ListItem>
                  {index < userList?.length - 1 && (
                    <Divider m={0} sx={{ margin: "0px !important" }} />
                  )}

                </div>
              ))}
            </List>
          </Grid>
          <Grid item xs={5} style={{ height: "450px" }}>
            {/* {defaultProps.center}
            {defaultProps.zoom} */}
            {coordinates.length != 0 ? <Maps locations={coordinates} /> : <></>}
          </Grid>
          <Grid item xs={5} pl={2}>
            {eventList.map((event) => (
              <MDBox className="slides">
                <MDTypography variant="button" display="block" gutterBottom>
                  Contact Name<b>: {event.contact_name}</b>
                </MDTypography>
                <MDTypography variant="button" display="block" gutterBottom>
                  Event Name<b>: {event.event_name}</b>
                </MDTypography>
                <MDTypography variant="button" display="block" gutterBottom>
                  Start Time<b>: {formatdate(event.event_startTime)}</b>
                </MDTypography>
                <MDTypography variant="button" display="block" gutterBottom>
                  End Time<b>: {formatdate(event.event_endTime)}</b>
                </MDTypography>
                <MDTypography variant="button" display="block" gutterBottom>
                  Assign Users<b>: {event.user_name}</b>
                </MDTypography>
                <MDTypography variant="button" display="block" gutterBottom>
                  Status
                  <b>: </b>
                  <Chip
                    label={event.status}
                    size="small"
                    style={{
                      backgroundColor:
                        event.status == "confirmed"
                          ? "green"
                          : event.status == "pending"
                            ? "yellow"
                            : "red",
                      color: "white",
                    }}
                  />
                </MDTypography>
                {/* <MDButton variant="contained" color="dark" size="small">
                  Appointment
                </MDButton> */}
              </MDBox>
            ))}
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
};

export default Default;
